<template>
  <b-card>
    <b-row>
      <b-button
        variant="adn"
        class="btn-filter"
        @click="toggleFilters"
      >
        {{ showFilters ? $t('hideFilter') : $t('showFilter') }}
        <feather-icon
          icon="FilterIcon"
          class="ml-50"
        />
      </b-button>
    </b-row>

    <b-row
      v-if="showFilters"
      class="filter-section"
    >
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.StoreId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="storeFrom"
          field="select"
          name="dealer"
          :options="stores"
          label="arabicName"
          @input="() => {
            $refs['items-table'].refreshTable();
          }"
          @change="() => {
            $refs['items-table'].refreshTable();
          }"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.DestinationStoreId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="storeTo"
          field="select"
          name="agent"
          :options="stores"
          label="arabicName"
          @input="() => {
            $refs['items-table'].refreshTable();
          }"
          @change="() => {
            $refs['items-table'].refreshTable();
          }"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="dateLabel"
          for="example-datepicker"
        >{{
          $t('fromDate')
        }}</label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.FromDate"
          locale="ar"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="fromDate"
          @input="() => {
            $refs['items-table'].refreshTable();
          }"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="dateLabel"
          for="example-datepicker2"
        >{{
          $t('toDate')
        }}</label>
        <b-form-datepicker
          id="example-datepicker2"
          v-model="filter.ToDate"
          locale="ar"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="toDate"
          @input="() => {
            $refs['items-table'].refreshTable();
          }"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="code"
          v-silent-search
          label-text="code"
          :value.sync="filter.Code"
          class="mb-0"
          name="code"
          @keydown.enter="
            (v) => {
              $refs['items-table'].refreshTable();
            }
          "
        />
      </b-col>
    </b-row>

    <div class="my-2">
      <div class="row justify-content-between">
        <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0 col-12">
          <b-button
            variant="primary"
            data-action-type="new"
            @click="
              (v) => {
                this.$router.push({ name: 'storage-transfer-new' });
              }
            "
          >
            {{ $t('new') }}
          </b-button>
          <vue-excel-xlsx
            :data="itemsArray"
            :columns="tableColumns"
            :filename="this.$route.name"
            :sheetname="'xlsxSheet'"
            class="btn btn-relief-success ml-1 p-0 ptn-sm"
          >
            <img
              src="@/assets/images/icons/xls.jpg"
              alt=""
              style="height: 39px;width: auto;"
            >
          </vue-excel-xlsx>
          <b-button
            variant="relief-danger"
            class="ml-1 p-0 ptn-sm"
            @click="printDocument"
          >
            <img
              src="@/assets/images/icons/pdf.jpg"
              alt=""
              style="height: 39px;width: auto;"
            >
          </b-button>
        </div>
      </div>
    </div>
    <g-table
      ref="items-table"
      :items="itemsSearchProvider"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
    >
      <template
        v-if="hideActions === false"
        #actions="{ item }"
      >
        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${item.id}-prev-icon`"
            v-permission=" 'editStorageTransfer' "
            icon="EyeIcon"
            data-action-type="preview"
            class="mx-1 clickable "
            :hidden="true"
            @click=" () => { edit(item) }"
          />
          <b-tooltip
            :title="$t('preview')"
            placement="bottom"
            :target="`invoice-row-${item.id}-prev-icon`"
          />
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission=" 'editStorageTransfer' "
            data-action-type="edit"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            @click=" () => { edit(item) }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            v-permission=" 'viewStorageTransfer' "
            variant="flat-success"
            class="btn-icon"
            size="sm"
            @click=" () => { print(item.id) }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="PrinterIcon"
              stroke="green"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission=" 'editStorageTransfer' "
            :disabled="item.isPosted"
            variant="flat-danger"
            data-action-type="delete"
            class="btn-icon"
            size="sm"
            @click="(v) => { remove(item) }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import reportMixin from '@/mixin/reportMixin';
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
  },
  mixins: [reportMixin],
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      filter: {
        DealerId: '',
        FromDealer: '',
        ToDealer: '',
        FromDate: '',
        ToDate: '',
        DestinationStoreId: null,
        StoreId: null,
        Code: null,
        OrderClause: '',
      },
      isTableBusy: false,
      totalRows: 0,
      stores: [],
      itemsArray: [],
      hideActions: false,
      showFilters: false,
    };
  },
  computed: {
    tableColumns() {
      return [
        {
        key: 'code',
        field: 'code',
        label: this.$t('code'),
        sortable: true
        },
        {
          key: 'transactionDate',
          field: 'transactionDate',
          label: this.$t('transactionDate'),
          sortable: true,
          formatter: (value, key, item) => {
            return this.getDate(item.transactionDate);
          },
        },
        {
          key: this.isRight ? 'storeArabicName' : 'storeEnglishName',
          label: this.$t('storeFrom'),
          sortable: true,
        },
        {
          key: this.isRight ? 'destinationArabicName' : 'destinationEnglishName',
          label: this.$t('storeTo'),
          sortable: true,
        },
        {
          key: 'updatedStatus',
          field: 'updatedStatus',
          label: this.$t('status'),
          sortable: false,
          formatter: (key, value, tanx) => {
            if (tanx.isPosted) return `<span class="badge bg-secondary bg-darken-1 p-50">${this.$t('posted')}</span>`
            return `<span class="badge border-0 bg-gradient-primary bg-darken-1 p-50"></span>`;
          },
        },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
    this.getStores();
  },
  methods: {
    hide() {
      this.hideActions = true
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    pdfExport(name) {
      html2canvas(document.getElementById('mainTable')).then(function(canvas){
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
          doc.save(`${name}.pdf`);
      });
    },
    printDocument() {
      this.hide()
        setTimeout(() => {this.pdfExport(this.$route.name)
        this.hideActions = false
        }, 500);
    },
    refreshItems() {
      this.$refs['items-table'].refreshTable();
    },
    getStores() {
      this.get({ url: 'Stores' }).then((data) => {
        if (this.currentBranch.stores && this.currentBranch.stores.length > 0) {
        const branchStores = this.currentBranch.stores.map(
          (item) => item.storeId
        );
        this.stores = data.filter(
          (item) => branchStores.indexOf(item.id) > -1
        );
      } else {
        this.stores = [];
      }
      });
    },
    itemsSearchProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;

      // this.totalRows = 0;
      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);
      this.isTableBusy = true;
      this.get({ url: `itemTransactions/storageTransfer${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount === 0 ? 1 : totalCount;
          data.forEach(element => {
           const destinationStore = this.stores.find((val) => val.id === element.destinationStoreId)
           element.destinationArabicName = destinationStore.arabicName;
           element.destinationEnglishName = destinationStore.englishName;
          });
          callback(data);
          this.itemsArray = data;
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      // Must return null or undefined to signal b-table that callback is being used
      return null;
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('Are you sure you want to delete?'),
        },
        () => {
          this.delete({
            url: 'ItemTransactions/storageTransfer',
            id: item.id,
          }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.refreshItems();
          });
        }
      );
    },
    edit(item) {
      this.$router.push({
        name: 'storage-transfer-edit',
        params: { id: item.id },
      });
    },
     print(id) {
      const printedItem = {
        id: id
      }
       this.printReport('StorageTransfer-ar', printedItem);
    },
  },
};
</script>

<style>
.filter-section {
  background-color: #f9f9f9;
  margin: 15px 0;
  padding: 25px 0 25px;
  border-radius: 7px;
  border-top-left-radius: 0;
  border: 2px solid #ad8884;
}

.btn-filter {
  margin-left: 14px;
  width: 157px;
  margin-bottom: -16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
